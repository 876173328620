<template>
    <tr
        :data-id="appointment._id"
        :data-committed="appointment.commited"
        :class="{
            'updating-appointment': isUpdating,
            'saved-appointment': isSaved,
            'group-bg': isExpanded && appointment.group_id,
            'selected': isSelected,
            'warning lighten-4': isClashExists
        }"
    >
        <td v-if="isPharmacy" class="ml-2">
            <v-checkbox
                @click="select"
                :value="isSelected"
                class="mt-0 ml-2"
                hide-details
            />
        </td>
        <td class="toggle-group">
            <v-icon
                v-if="appointment.group_first"
                @click="$emit('expand', !isExpanded)"
                color="#333"
                size="20"
            >
                {{ isExpanded ? 'mdi-folder-open' : 'mdi-folder' }}
            </v-icon>
        </td>

        <td class="date" :data-date="appointment.date">
            <v-tooltip top max-width="300">
                <template v-slot:activator="{ on, attrs }">
                    <span
                        :class="appointment.commited && 'committed'"
                        v-bind="attrs"
                        v-on="appointment.commited && on"
                    >
                        {{ formatDate(appointment.date) }}
                    </span>
                </template>
                <span>Fest zugesagter Termin</span>
            </v-tooltip>
            <v-tooltip v-if="appointment.formatted_created_updated" top max-width="300">
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        color="secondary"
                        dark
                        size="20"
                        v-bind="attrs"
                        v-on="on"
                    >
                        mdi-calendar-month
                    </v-icon>
                </template>
                <span>{{ appointment.formatted_created_updated }}</span>
            </v-tooltip>

            <v-tooltip v-if="appointment.nightshift" top max-width="300">
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        color="secondary"
                        size="20"
                        v-bind="attrs"
                        v-on="on"
                    >
                        mdi-cloud
                    </v-icon>
                </template>
                <span>Nachtdienst</span>
            </v-tooltip>

            <v-tooltip v-if="appointment.self_assigned" top max-width="300">
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        color="secondary"
                        size="20"
                        v-bind="attrs"
                        v-on="on"
                    >
                        mdi-account-check
                    </v-icon>
                </template>
                <span>Der Freiberufler hat sich diesem Termin selbst direkt zugewiesen</span>
            </v-tooltip>

            <v-tooltip v-if="appointment.pricing_suggestions" top max-width="300">
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        color="secondary"
                        size="20"
                        class="pricing-suggestions"
                        :data-title="`Am Termin gespeicherte Bepreisung: ${appointment.formatted_pricing_suggestions}`"
                        v-bind="attrs"
                        v-on="on"
                    >
                        mdi-currency-eur
                    </v-icon>
                </template>
                <span>Am Termin gespeicherte Bepreisung: {{ appointment.formatted_pricing_suggestions }}</span>
            </v-tooltip>

            <div>
                <v-chip
                    v-for="service in appointment.display_services_with_pricing"
                    :color='getServiceColor(service.service)'
                    class="mr-1 px-2"
                    dark
                    x-small
                    label
                >
                    <v-tooltip top max-width="300">
                        <template v-slot:activator="{ on, attrs }">
                            <b
                                v-bind="attrs"
                                v-on="service.pricing_text && on"
                            >{{ service.title }}</b>
                        </template>
                        <span>{{ service.pricing_text }}</span>
                    </v-tooltip>
                </v-chip>
            </div>

            <div v-if="appointment.hba">
                <v-chip
                    class="mt-1 px-2 hba"
                    color="purple"
                    tag="b"
                    dark
                    x-small
                    label
                    outlined
                >
                    HBA erforderlich
                </v-chip>
            </div>

            <b v-for="holiday in appointment.holidays" class="holiday">{{ holiday }}</b>
            <div>
                <assign-to-admin
                    :key="appointment._id"
                    :appointment_id="appointment._id"
                    :admin_id="appointment.admin_id"
                    @selected-user="onPharmacistSelected"
                />
            </div>
        </td>

        <td class="group-enddate">
            <span v-if="appointment.group_first">
                {{ formatDate(appointment.group_enddate) }}
            </span>
        </td>

        <td class="group-size">
            <span v-if="appointment.group_first">
                ({{ appointment.group_size }})
            </span>
        </td>

        <td class="pharmacy">
            <a :href="`/pharmacies/${appointment.pharmacy._id}`">{{ appointment.pharmacy.name }}</a>
            <a :href="`/users/admin/?city=${appointment.pharmacy.zip}`" target="_blank">
                <v-icon
                    small
                    color="blue-grey"
                >
                    mdi-earth
                </v-icon>
            </a>
            <div class="text--secondary">{{ appointment.pharmacy.city }}{{", " + appointment.pharmacy.zip }}</div>
        </td>

        <td class="time">{{ appointment.time }}</td>

        <td v-if="!appointment.group_id || appointment.group_first">
            <div class="d-flex align-center relative">
                <v-alert
                    v-if="isClashExists"
                    type="warning"
                    icon="mdi-alert"
                    width="300"
                    class="dates-clash"
                    dismissible
                    dense
                >
                    Es gibt Terminkollisionen
                </v-alert>
                <div :data-assignee-id="appointment?.pharmacist ? appointment.pharmacist._id : null">
                    <PharmacistSearchDropdown
                        v-if="wfState == 'open' || wfState == 'allocated' || wfState == 'expired'"
                        class="autocomplete"
                        :services="appointment.available_services"
                        :pharmacy="appointment.pharmacy"
                        :value="selectedPharmacist"
                        @input="onPharmacistSelected"
                        :isWarning="isClashExists"
                    />
                    <v-btn
                        @click="unassign"
                        v-if="wfState === 'allocated'"
                        :disabled="!selectedPharmacist?._id"
                        class="d-block mt-2 unassign"
                        rounded
                        small
                    >
                        <v-icon left class="mr-1">mdi-cancel</v-icon>
                        FB entfernen
                    </v-btn>
                </div>
                <a
                    v-if="selectedPharmacist?._id
                        && (wfState == 'open' || wfState == 'allocated')"
                    :href="`/pharmacists/${selectedPharmacist._id}`"
                    target="_blank"
                    class="ml-3 pharmacist-link"
                >
                    <v-tooltip top max-width="300">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                size="20"
                                color="blue"
                                v-bind="attrs"
                                v-on="on"
                            >
                                mdi-open-in-new
                            </v-icon>
                        </template>
                        <span>{{ selectedPharmacist.firstname }} {{ selectedPharmacist.lastname }}</span>
                    </v-tooltip>

                </a>
                <div v-if="wfState == 'completed_no_apo_invoice'
                    || wfState == 'apobilanz'
                    || wfState == 'tempInvoice'
                    || wfState == 'invoice'
                    || wfState == 'paid'"
                >
                    <a
                        :href="`/pharmacists/${selectedPharmacist._id}`"
                        target="_blank"
                    >
                        {{ appointment.pharmacist.firstname }} {{ appointment.pharmacist.lastname }}
                    </a>
                    <v-tooltip
                        v-if="appointment.user_state == 'approved'"
                        top
                        max-width="170"
                        color="#00b000"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                small
                                v-bind="attrs"
                                v-on="on"
                                color="green darken-2"
                            >
                                mdi-checkbox-marked-circle
                            </v-icon>
                        </template>
                        <span>Alles war ok und wie besprochen.</span>
                    </v-tooltip>
                    <v-tooltip
                        v-else-if="appointment.user_state == 'awaiting'"
                        top
                        max-width="250"
                        color="#fb8c00"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                small
                                v-bind="attrs"
                                v-on="on"
                                color="orange darken-2"
                            >
                                mdi-information
                            </v-icon>
                        </template>
                        <v-row class="v-application">
                            <v-col
                                cols="12"
                                class="pb-0 text-subtitle-1 font-weight-bold"
                            >
                                Es gab Änderungen:
                                <v-divider dark color="#fff"></v-divider>
                            </v-col>
                            <v-col cols="12">
                                <div v-for="awaitingComment in appointment.awaiting_comments">
                                    <b>{{ awaitingComment.label }}</b>
                                    <span>{{ awaitingComment.comment }}</span>
                                </div>
                            </v-col>
                        </v-row>
                    </v-tooltip>
                    <v-tooltip v-else top max-width="220">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                small
                                v-bind="attrs"
                                v-on="on"
                            >
                                mdi-help-circle
                            </v-icon>
                        </template>
                        <span>Termin muss durch FA noch bestätigt werden.</span>
                    </v-tooltip>
                    <v-tooltip v-if="appointment.pharmacist.asset" top max-width="220">
                        <template v-slot:activator="{ on, attrs }">
                            <a
                                :href="`/assets/${appointment.pharmacist.asset}`"
                                target="_blank"
                            >
                                <v-icon
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    mdi-school
                                </v-icon>
                            </a>
                        </template>
                        <span>
                            Aprobationsurkunde {{ appointment.pharmacist.firstname }} {{ appointment.pharmacist.lastname }}
                        </span>
                    </v-tooltip>
                </div>
                <AvailablePharmacistsDialogButton
                    v-if="wfState == 'inquired' || wfState == 'open'"
                    :wfState="wfState"
                    :pharmacy="appointment.pharmacy"
                    :appointment="appointment"
                    :services="appointment.available_services"
                    :date="appointment.date"
                    @selected-user="onPharmacistSelected"
                />
            </div>

        </td>

        <td v-if="appointment.group_id && !appointment.group_first" class="text--secondary group-pharmacist">
            Name des genauen Vertreters steht in Kürze fest
        </td>

        <td class="travelcosts">
            <v-tooltip
                top
                max-width="250"
                :color="(maxCostsInfo && !appointment.group_id) ? '#fb8c00' : '#ff5252'"
                :disabled="(appointment.travelcosts <= maxTravelCosts) && (appointment.group_id || !maxCostsInfo)"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-if="wfState === 'open'
                            || wfState === 'allocated'
                            || wfState === 'completed_no_apo_invoice'
                            || wfState === 'expired'"
                        v-model="travelCosts"
                        :error="appointment.travelcosts > maxTravelCosts && !appointment.group_id"
                        :class="maxCostsInfo && !appointment.group_id && 'warning-input'"
                        @input="isUpdating = true; isSaved = false"
                        type="number"
                        min="0"
                        style="min-width: 70px;"
                        hide-details
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                    />
                    <span v-else>
                        {{ $n(appointment.travelcosts, 'currency', 'de-DE') }}
                    </span>
                </template>
                <span v-if="maxCostsInfo && !appointment.group_id">{{ maxCostsInfo }}</span>
                <span v-else>Die Reisekosten sollen bei Einzelterminen nicht höher als {{ $n(maxTravelCosts, 'currency', 'de-DE') }} sein.</span>
            </v-tooltip>
        </td>
        <td class="pricing">
            <div v-for="price, i in appointment.price_details" :data-service="price.service">
                <v-tooltip right nudge-right="-30" max-width="220">
                    <template v-slot:activator="{ on, attrs }">
                        <section
                            v-if="appointment.billing == 'hours'"
                            :class="i == 1 && 'mt-2'"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <div>
                                <p>
                                    {{ $n(price.total_apo, 'currency', 'de-DE')}}
                                </p>
                                <p>
                                    ({{ appointment.quantity }} St. x <span class="hourly-apo-rate">{{ $n(price.apo_rate, 'currency', 'de-DE') }})</span>
                                </p>
                            </div>
                            <div class="text--secondary total-fb-costs" :class="price.is_fb_pricier_than_apo ? 'negative-balance' : ''">
                                <p>{{ $n(price.total_fb, 'currency', 'de-DE') }}</p>
                                <p>
                                    ({{ appointment.quantity }} St. x {{ $n(price.fb_rate, 'currency', 'de-DE') }}) (FB)
                                </p>
                            </div>
                        </section>
                        <section v-else v-bind="attrs" v-on="on">
                            <div>
                                <p>{{ $n(price.total_apo, 'currency', 'de-DE') }}</p>
                            </div>
                            <div class="text--secondary total-fb-costs">
                                <p>{{ $n(price.total_fb, 'currency', 'de-DE') }} (FB)</p>
                            </div>
                        </section>
                    </template>
                    <span>
                        {{ price.service_name }}
                    </span>
                    <span v-if="price.applied_discount && price.applied_discount.discountAmount">
                        , Rabattname: {{ price.applied_discount.discountName }}, Rabattwert:  - {{ $n(price.applied_discount.discountAmount, 'currency', 'de-DE') }}
                    </span>
                </v-tooltip>
                <div v-if="appointment.other_costs">{{ $n(appointment.other_costs, 'currency', 'de-DE') }}</div>
            </div>
        </td>

        <td class="action-buttons">
            <div class="mb-2 text-center">
                <v-tooltip v-if="appointment.comment" top max-width="300">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            color="secondary"
                            dark
                            size="20"
                            v-bind="attrs"
                            v-on="on"
                        >
                            mdi-comment
                        </v-icon>
                    </template>
                    <v-row class="v-application">
                        <v-col cols="12" class="pb-0 text-subtitle-1 font-weight-bold">
                            Kommentar
                            <v-divider dark color="#fff"></v-divider>
                        </v-col>
                        <v-col cols="12">
                            <span>{{ appointment.comment }}</span>
                        </v-col>
                    </v-row>
                </v-tooltip>
                <v-tooltip v-if="appointment.internal_comment" top max-width="300">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            color="secondary"
                            dark
                            size="20"
                            v-bind="attrs"
                            v-on="on"
                        >
                            mdi-comment-text-multiple
                        </v-icon>
                    </template>
                    <v-row class="v-application">
                        <v-col cols="12" class="pb-0 text-subtitle-1 font-weight-bold">
                            Interner Kommentar
                            <v-divider dark color="#fff"></v-divider>
                        </v-col>
                        <v-col cols="12">
                            <span>{{ appointment.internal_comment }}</span>
                        </v-col>
                    </v-row>
                </v-tooltip>
                <v-tooltip v-if="appointment.comment_to_fb" top max-width="300">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            color="secondary"
                            dark
                            size="20"
                            v-bind="attrs"
                            v-on="on"
                        >
                            mdi-comment-account-outline
                        </v-icon>
                    </template>
                    <v-row class="v-application">
                        <v-col cols="12" class="pb-0 text-subtitle-1 font-weight-bold">
                            Kommentar für Freiberufler
                            <v-divider dark color="#fff"></v-divider>
                        </v-col>
                        <v-col cols="12">
                            <span>{{ appointment.comment_to_fb }}</span>
                        </v-col>
                    </v-row>
                </v-tooltip>
            </div>

            <div v-if="!isUpdating" class="justify-center flex-wrap">
                <ProposePharmacistDialog
                    v-if="wfState !== 'inquired' && wfState !== 'deleted'"
                    :pharmacy="appointment.pharmacy"
                    :appointmentid="appointment._id"
                    :disabled="appointment.hidden"
                />
                <MarkAsHiddenBtn
                    v-if="wfState !== 'deleted'"
                    :disabled="wfState != 'open' && wfState != 'inquired'"
                    :is_appointment_hidden="appointment.hidden"
                    :appointmentid="appointment._id"
                    @update="appointments => this.$emit('update-appointments', appointments)"
                />
                <ToggleSelfAssignBtn
                    v-if="wfState !== 'deleted'"
                    :disabled="wfState != 'open' && wfState != 'inquired'"
                    :isSelfAssignDisabled="appointment.disable_self_assign"
                    :appointmentid="appointment._id"
                    @update="appointments => this.$emit('update-appointments', appointments)"
                />
                <ToggleDirectProposalBtn
                    v-if="wfState !== 'deleted'"
                    :disabled="wfState != 'open' && wfState != 'inquired'"
                    :isDirectProposalAllowed="appointment.allow_direct_proposal"
                    :appointmentid="appointment._id"
                    @update="appointments => this.$emit('update-appointments', appointments)"
                />
                <v-btn
                    v-if="wfState !== 'deleted'"
                    :href="`/pharmacies/${appointment.pharmacy._id}/${appointment._id}/tickets`"
                    class="tickets"
                    min-width="30"
                    small
                >
                    <v-icon small>mdi-list-box-outline</v-icon>
                </v-btn>
                <PriceSuggestion
                    v-if="wfState !== 'deleted'"
                    :pharmacyId="appointment.pharmacy._id"
                    :appointmentId="appointment._id"
                ></PriceSuggestion>
                <appointment-manager
                    :appointment="appointment"
                    :pharmacy="appointment.pharmacy"
                ></appointment-manager>
                <v-btn
                    v-if="wfState == 'open' || wfState == 'inquired'"
                    @click="isDeleteAppointmentDialogOpen = true"
                    color="error"
                    min-width="30"
                    dark
                    small
                    class="delete-btn"
                >
                    <v-icon small>mdi-trash-can-outline</v-icon>
                </v-btn>
                <v-btn
                    @click="commit"
                    v-if="!appointment.commited
                        && (appointment.group_first || !appointment.group_id)
                        && wfState == 'open'"
                    :disabled="isLoading"
                    color="primary"
                    class="commit-with-email"
                    small
                    outlined
                >
                    Zusage an Apotheke
                </v-btn>

                <ApprovalReminderDialogButton
                    v-if="wfState == 'completed_no_apo_invoice' && (appointment.user_state === '' || appointment.user_state === null)"
                    v-model="isApprovalReminderDialogOpen"
                    :pharmacist_id="appointment.pharmacist._id"
                />

                <PharmacistsRequests
                    v-if="(wfState == 'open' || wfState == 'allocated' || wfState == 'deleted')
                        && (!appointment.group_id || appointment.group_first)"
                    :appointment_data="appointment"
                    :pharmacy_data="appointment.pharmacy"
                    :pharmacist_data="appointment.pharmacist"
                    :known_pharmacists_data="appointment.known_pharmacists"
                    :num_of_requests="appointment.pharmacist_requests_count"
                    @selected-user="onPharmacistSelected"
                />
            </div>

            <div v-else class="text-center">
                <v-tooltip
                    v-if="wfState == 'open'
                        || wfState == 'allocated'
                        || wfState === 'completed_no_apo_invoice'
                        || wfState === 'expired'"
                    top
                    max-width="400"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            @click="checkCommittedState('update')"
                            color="cyan lighten-1"
                            min-width="70"
                            class="save"
                            small
                            dark
                            v-bind="attrs"
                            v-on="on"
                        >
                            Speichern
                        </v-btn>
                    </template>
                    <span>Speichern, es werden keine E-Mails versendet</span>
                </v-tooltip>
                <v-tooltip
                    v-if="(!appointment.group_id || appointment.group_first)
                        && (wfState == 'open' || wfState == 'expired')
                        && selectedPharmacist?._id"
                    max-width="400"
                    top
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            @click="checkCommittedState('update_ext/false')"
                            color="cyan lighten-1"
                            min-width="70"
                            class="mt-2 save-with-email"
                            small
                            dark
                            v-bind="attrs"
                            v-on="on"
                        >
                            Speichern + E-Mails senden
                        </v-btn>
                    </template>
                    <span>Speichern und E-Mails senden, aber ohne Unterkunftsemail</span>
                </v-tooltip>
                <v-tooltip v-if="appointment.group_first && appointment.is_part_of_a_blockbuster && selectedPharmacist?._id" top max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            @click="checkCommittedState('update_ext/true')"
                            color="cyan lighten-1"
                            min-width="70"
                            class="mt-2 save-with-accommodation"
                            small
                            dark
                            v-bind="attrs"
                            v-on="on"
                        >
                            S + E + Unterkunft
                        </v-btn>
                    </template>
                    <span>Speichern und E-Mails inklusive Unterkunftsemail senden</span>
                </v-tooltip>
            </div>
        </td>

        <DeleteAppointmentDialog
            v-model="isDeleteAppointmentDialogOpen"
            @confirm-deletion="deleteAppointment(appointment.pharmacy._id, appointment._id)"
        />
        <ConfirmationDialog
            v-model="isCommittedDialogOpen"
            message="Achtung: Der Termin wurde noch nicht fest zugesagt! Wollen Sie ihn wirklich speichern?"
            @save="saveAppointment()"
        />
    </tr>
</template>

<script>
    import axios from 'axios';
    import _ from 'lodash';
    import moment from 'moment'
    import { mapMutations } from 'vuex';

    import AvailablePharmacistsDialogButton from './AvailablePharmacistsDialogButton.vue';
    import MarkAsHiddenBtn from './MarkAsHiddenBtn.vue';
    import DeleteAppointmentDialog from './DeleteAppointmentDialog.vue';
    import ApprovalReminderDialogButton from './ApprovalReminderDialogButton.vue';
    import ConfirmationDialog from './ConfirmationDialog.vue';
    import ProposePharmacistDialog from './ProposePharmacistDialog.vue';
    import PharmacistSearchDropdown from '../pharmacist/PharmacistSearchDropdown.vue';
    import PharmacistsRequests from './PharmacistsRequests.vue';
    import ToggleSelfAssignBtn from './ToggleSelfAssignBtn.vue';
    import ToggleDirectProposalBtn from './ToggleDirectProposalBtn.vue';
    import PriceSuggestion from './PriceSuggestion.vue';

    export default {
        components: {
            AvailablePharmacistsDialogButton,
            DeleteAppointmentDialog,
            ConfirmationDialog,
            ProposePharmacistDialog,
            PharmacistSearchDropdown,
            MarkAsHiddenBtn,
            PharmacistsRequests,
            ToggleSelfAssignBtn,
            ToggleDirectProposalBtn,
            PriceSuggestion,
            ApprovalReminderDialogButton
        },
        props: {
            appointment: Object,
            wfState: String,
            expand: Function,
            isExpanded: Boolean,
            isSelected: Boolean,
            isPharmacy: Boolean,
            groupAppointments: Array
        },
        data() {
            return {
                isAvailablepharmacistsDialogOpen: false,
                isDeleteAppointmentDialogOpen: false,
                isCommittedDialogOpen: false,
                travelCosts: this.appointment.travelcosts,
                selectedPharmacist: this.appointment.pharmacist,
                isLoading: false,
                isUpdating: false,
                isSaved: false,
                isClashExists: false,
                maxCostsInfo: '',
                path: ''
            }
        },
        methods: {
            formatDate(date) {
                if (!date) return ''
                return moment(date).format('DD-MM-yyyy')
            },
            async onPharmacistSelected(pharmacist) {
                if (!pharmacist) return
                this.selectedPharmacist = pharmacist;
                const pharmacyId = this.appointment.pharmacy._id;
                const appointmentId = this.appointment._id;

                await this.getTravelCosts(this.appointment._id, pharmacist._id);
                const status = await this.checkDates(pharmacyId, appointmentId);
                if (status === 'wait') this.isClashExists = true
            },

            async getTravelCosts(appointmentId, pharmacistId) {
                try {
                    const response = await axios.get(`/api/appointments/travelcosts`, { params: {
                        appointment_id: appointmentId,
                        pharmacist_id: pharmacistId
                    } });
                    this.travelCosts = response.data[0].costs;
                    if (response.data[0].is_capped) {
                        this.maxCostsInfo =
                            `Die maximalen Kosten für Einzeltermine sind auf
                            ${response.data[0].pharmacy_max_travel_cost}€ begrenzt
                            (${response.data[0].max_distance.toFixed(0)} km).
                            Die tatsächen Kosten wären bei ${response.data[0].non_capped_costs}€
                            (${response.data[0].distance.toFixed(0)} km).`;
                    }
                    this.isUpdating = true;
                    this.isSaved = false;

                    if (this.groupAppointments) {
                        const appointmentsCostsInfo = response.data.slice(1);
                        this.groupAppointments.forEach((appointment, i) => {
                            this.$emit('update-group-travelcosts', {
                                appointmentId: appointment._id,
                                travelcosts: appointmentsCostsInfo[i].costs
                            })
                        })
                    }
                } catch (error) {
                    this.alertError(error)
                }
            },

            async checkDates(pharmacyId, appointmentId) {
                if (!this.selectedPharmacist) return
                try {
                    const response = await axios.post(`/pharmacies/${pharmacyId}/check_date`, {
                        a_id: appointmentId,
                        pharmacist_id: this.selectedPharmacist._id
                    })
                    return response.data.status
                } catch (error) {
                    this.alertError(error)
                }
            },

            checkCommittedState(path) {
                if (this.appointment.commited) {
                    this.saveAppointment(path)
                } else {
                    this.path = path;
                    this.isCommittedDialogOpen = true
                }
            },

            async saveAppointment(path=this.path) {
                const appointmentId = this.appointment._id;
                const pharmacyId = this.appointment.pharmacy._id;
                const service = this.selectedPharmacist?.display_services_full ? this.selectedPharmacist.display_services_full[0].service : this.selectedPharmacist?.services[0];
                let travelcostsByAppointmentId = `${appointmentId}=${this.travelCosts}`;
                if (this.isExpanded) {
                    this.groupAppointments.forEach(appointment => {
                        travelcostsByAppointmentId = travelcostsByAppointmentId.concat(',', `${appointment._id}=${appointment.travelcosts}`);
                    })
                }

                try {
                    await axios.post(`/pharmacies/${pharmacyId}/${appointmentId}/${path}`, {
                        pharmacist_id: this.selectedPharmacist?._id || '__unset__',
                        service,
                        travelcosts: this.travelCosts,
                        travelcostsByAppointmentId: travelcostsByAppointmentId
                    });
                    this.isUpdating = false;
                    this.isSaved = true;

                } catch (error) {
                    this.alertError(error)
                }
            },
            async deleteAppointment(pharmacyId, appointmentId) {
                try {
                    await axios.post(`/pharmacies/${pharmacyId}/${appointmentId}/remove`);
                    this.isOpen = false;
                    window.location.reload()
                } catch (error) {
                    this.alertError()
                }
            },
            getServiceColor(service='substitution') {
                const servicesColors = {
                    substitution: 'cyan',
                    pta: 'warning',
                    pka: 'success',
                    qms: 'red',
                    storno: 'red'
                }
                return servicesColors[service]
            },
            async commit() {
                const appointmentId = this.appointment._id;
                const pharmacyId = this.appointment.pharmacy._id;
                this.isLoading = true;
                try {
                    const response = await axios.post(`/pharmacies/${pharmacyId}/${appointmentId}/commit_with_mail_ticket`);
                    const appointments = response.data;
                    this.$emit('update-appointments', appointments);
                    this.alertSuccess('Termine zugesagt')
                } catch (error) {
                    this.alertError()
                } finally {
                    this.isLoading = false
                }
            },
            unassign() {
                this.selectedPharmacist = null
                this.isUpdating = true
            },
            async select() {
                if (this.appointment.group_first && !this.isExpanded) {
                    await this.$emit('expand', true);
                    this.$emit('select-group', !this.isSelected);
                } else {
                    this.$emit('select', !this.isSelected);
                }
            },
            ...mapMutations({
                alertError: 'alert/error',
                alertSuccess: 'alert/success',
            })
        },
        watch: {
            appointment: {
                handler(updatedAppointment) {
                    this.travelCosts = updatedAppointment.travelcosts;
                    this.maxCostsInfo = '';
                    this.isUpdating = false;
                    this.isSaved = false;
                    this.selectedPharmacist = updatedAppointment.pharmacist;
                },
                deep: true
            },
        },
        computed: {
            maxTravelCosts() {
                return this.appointment.pharmacy.max_travel_cost || 80
            }
        },
    }
</script>

<style scoped>
    td {
        padding: 8px !important;
    }
    p {
        margin: 0 !important;
    }
    tr {
        transition: all 0.3s ease;
    }
    .group-bg {
        background-color: rgba(239, 239, 239, 0.537)
    }
    .selected {
        background-color: rgba(0, 128, 255, 0.093) !important;
    }
    .fb-button {
        padding: 0 !important;
        margin-left: 10px;
    }
    .updating-appointment, .updating-appointment:hover {
        background: #ffffe0 !important;
    }
    .saved-appointment, .saved-appointment:hover {
        background: #e0ffe0 !important
    }
    .holiday {
        color: maroon;
        margin-top: 7px;
    }
    .autocomplete {
        width: 150px;
    }
    .toggle-group { width: 36px; }
    .date { width: 200px; }
    .group-enddate { width: 100px; }
    .group-size { width: 50px; }
    .pharmacy { width: 166px; }
    .time { width: 160px; }
    .group-pharmacist { width: 222px; }
    .pricing { width: 170px; }
    .action-buttons { width: 170px; }
    .flex-wrap {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
    }
    .commit-with-email {
        font-size: 12px !important;
        padding-inline: 7px !important;
    }
    .negative-balance {
        color: red;
    }
    .relative {
        position: relative;
    }
    .dates-clash {
        position: absolute;
        bottom: 30px;
        right: 0;
    }
    .unassign {
        position: absolute;
    }
</style>
<style>
    .committed {
        background-color: #FCFB93;
    }
    .warning-input fieldset {
        color: #fb8c00 !important;
        caret-color: #fb8c00 !important;
        border: 2px solid !important;
    }
</style>