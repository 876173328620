<template>
    <v-container :class="`mx-0 px-0`">
        <v-row>
            <v-col cols="4" class="d-flex">
                <span>Mittagspause</span>
                <v-switch
                    v-model="hasBreak"
                    class="toggle-secondshift mt-0 pt-0 ml-2"
                ></v-switch>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-row class="time">
                    <v-col cols="12" md="6" lg="3">
                        <span class="text--secondary caption">Schichtbeginn vor der Pause*</span>
                        <vc-date-picker
                            v-model="beginTimeBeforeBreak"
                            mode="time"
                            is-required
                            is24hr
                            class="firstshift-begin"
                        />
                    </v-col>
                    <v-col cols="12" md="6" lg="3">
                        <span class="text--secondary caption">Schichtende vor der Pause*</span>
                        <vc-date-picker
                            v-model="endTimeBeforeBreak"
                            mode="time"
                            :validHours="{min: endTimeBeforeBreakMinHour}"
                            is-required
                            is24hr
                            class="firstshift-end"
                        />
                    </v-col>
                    <v-col v-if="hasBreak" cols="12" md="6" lg="3">
                        <span class="text--secondary caption">Schichtbeginn nach der Pause</span>
                        <vc-date-picker
                            v-model="beginTimeAfterBreak"
                            mode="time"
                            :validHours="{min: beginTimeAfterBreakMinHour}"
                            is-required
                            is24hr
                            class="secondshift-begin"
                        />
                    </v-col>
                    <v-col v-if="hasBreak" cols="12" md="6" lg="3">
                        <span class="text--secondary caption">Schichtende nach der Pause</span>
                        <vc-date-picker
                            v-model="endTimeAfterBreak"
                            mode="time"
                            :validHours="{min: endTimeAfterBreakMinHour}"
                            is-required
                            is24hr
                            class="secondshift-end"
                        />
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="12">
                <v-row>
                    <v-col v-if="hasBreak" cols="12" md="3" role="paid-break">
                        <v-checkbox
                            v-model="isBreakPaid"
                            class="mt-0"
                            label="bezahlte Pause"
                            color="green"
                            dense
                        />
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field
                            label="Berechnete Arbeitszeit"
                            :value="totalWorkingHours"
                            disabled
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="AUSGABE"
                            :value="workStartAndEndTimes"
                            dense
                            disabled
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import moment from 'moment'

    const defaultBeginTime = '1900-01-01T08:00:00';
    const defaultEndTime = '1900-01-01T18:00:00';
    const NDdefaultBeginTime = '1900-01-01T20:00:00';
    const NDdefaultEndTime = '1900-01-02T07:00:00';

    const TIME_FORMAT = 'HH:mm';

    function timeStringToMoment(timeString) {
        return moment(`1900-01-01T${timeString}:00`);
    }

    function momentToTimeString(m) {
        const s = m.format(TIME_FORMAT);
        return s == "00:00" ? "24:00" : s;
    }

    function calculatedTimePeriod(start, end) {
        return end.clone().subtract({
            hours: start.hours(),
            minutes: start.minutes(),
            seconds: start.seconds(),
            milliseconds: start.milliseconds(),
        });
    }

    export default {
        props: {
            value: Object,
            nightshift: Boolean,
            requiredRules: Array
        },
        data() {
            return {
                internalBeginTimeBeforeBreak: moment(defaultBeginTime),
                internalEndTimeBeforeBreak: moment(defaultEndTime),
                internalBeginTimeAfterBreak: null,
                internalEndTimeAfterBreak: null,
                isBreakPaid: false,
                hasBreak: false,
            }
        },
        created() {
            const { time_details } = this.value
            if(this.value.begin) {
                this.internalBeginTimeBeforeBreak = moment(this.value.begin)
            }
            if(time_details.break_start_time) {
                this.internalEndTimeBeforeBreak = moment(time_details.break_start_time)
            }
            if(time_details.break_end_time) {
                this.internalBeginTimeAfterBreak = moment(time_details.break_end_time)
            }
            if(time_details.break_start_time && time_details.break_end_time) {
                this.internalEndTimeAfterBreak = moment(this.value.end)
            }
            this.isBreakPaid = !!time_details.paid_break
            if (this.nightshift) {
                this.hasBreak = false;
            }
            if(this.value?.time_details?.break_start_time) {
                this.hasBreak = true;
            }

        },
        updated() {
            this.$emit('input', this.openingHoursData);
        },
        computed: {
            beginTimeBeforeBreak: {
                get() {
                    return this.internalBeginTimeBeforeBreak.toISOString();
                },
                set(value) {
                    this.internalBeginTimeBeforeBreak = moment(value);
                    if (this.internalEndTimeBeforeBreak.isSameOrBefore(this.internalBeginTimeBeforeBreak)) {
                        // If the user selects a begin time that exceeds the end time, we force change the end time to be one-hour after the begin time
                        this.internalEndTimeBeforeBreak = this.internalBeginTimeBeforeBreak.clone().add(1, "hours")
                    }
                }
            },

            endTimeBeforeBreak: {
                get() {
                    return this.internalEndTimeBeforeBreak.toISOString();
                },
                set(value) {
                    this.internalEndTimeBeforeBreak = moment(value);
                }
            },

            beginTimeAfterBreak: {
                get() {
                    if (!this.internalBeginTimeAfterBreak) return null
                    return this.internalBeginTimeAfterBreak.toISOString();
                },
                set(value) {
                    this.internalBeginTimeAfterBreak = moment(value);
                    if (this.internalBeginTimeAfterBreak && this.internalEndTimeAfterBreak && this.internalEndTimeAfterBreak.isSameOrBefore(this.internalBeginTimeAfterBreak)) {
                        // If the user selects a begin time that exceeds the end time, we force change the end time to be one-hour after the begin time
                        this.internalEndTimeAfterBreak = this.internalBeginTimeAfterBreak.clone().add(1, "hours")
                    }
                }
            },

            endTimeAfterBreak: {
                get() {
                    if (!this.internalEndTimeAfterBreak) return null
                    return this.internalEndTimeAfterBreak.toISOString();
                },
                set(value) {
                    this.internalEndTimeAfterBreak = moment(value);
                }
            },

            endTimeBeforeBreakMinHour() {
                return this.internalBeginTimeBeforeBreak.clone().add(1, "hours").format("H")
            },

            beginTimeAfterBreakMinHour() {
                return this.internalEndTimeBeforeBreak.clone().add(1, "hours").format("H")
            },

            endTimeAfterBreakMinHour() {
                if (!this.internalBeginTimeAfterBreak) {
                    return this.beginTimeAfterBreakMinHour
                }
                return this.internalBeginTimeAfterBreak.clone().add(1, "hours").format("H")
            },

            totalWorkingHours() {
                const timeBeforeBreak = calculatedTimePeriod(this.internalBeginTimeBeforeBreak, this.internalEndTimeBeforeBreak)

                if (this.internalBeginTimeAfterBreak && this.internalEndTimeAfterBreak) { // When a user selects begin & end times after break
                    if (this.isBreakPaid) { // when a user checks the break paid checkbox
                        const totalHours = calculatedTimePeriod(this.internalBeginTimeBeforeBreak, this.internalEndTimeAfterBreak);
                        return totalHours.format(TIME_FORMAT)
                    }
                    const timeAfterBreak = calculatedTimePeriod(this.internalBeginTimeAfterBreak, this.internalEndTimeAfterBreak)
                    const totalHours = timeAfterBreak.clone().add({
                        hours: timeBeforeBreak.hours(),
                        minutes: timeBeforeBreak.minutes(),
                        seconds: timeBeforeBreak.seconds(),
                        milliseconds: timeBeforeBreak.milliseconds(),
                    });
                    return totalHours.format(TIME_FORMAT)
                }

                return timeBeforeBreak.format(TIME_FORMAT)
            },

            breakDuration() {
                if(this.hasBreak && this.internalEndTimeBeforeBreak && this.internalBeginTimeAfterBreak) {
                    const breakDuration = calculatedTimePeriod(this.internalEndTimeBeforeBreak, this.internalBeginTimeAfterBreak);
                    const breakDurationInHours = breakDuration.hours();
                    const breakDurationInMinutes = breakDuration.minutes();
                    return breakDurationInHours + (breakDurationInMinutes / 60);
                }
                return;
            },

            workStartAndEndTimes() {
                const beginTimeBeforeBreak = momentToTimeString(this.internalBeginTimeBeforeBreak).replace(':', '.');
                const endTimeBeforeBreak = momentToTimeString(this.internalEndTimeBeforeBreak).replace(':', '.');

                if (this.hasBreak && this.internalBeginTimeAfterBreak && this.internalEndTimeAfterBreak) {
                    const beginTimeAfterBreak = momentToTimeString(this.internalBeginTimeAfterBreak).replace(':', '.');
                    const endTimeAfterBreak = momentToTimeString(this.internalEndTimeAfterBreak).replace(':', '.');

                    return `${beginTimeBeforeBreak} - ${endTimeBeforeBreak} Uhr  //  ${beginTimeAfterBreak} - ${endTimeAfterBreak} Uhr ${this.isBreakPaid ? `(bezahlte Pause ${this.breakDuration} Std. )` : ''}`
                }

                return `${beginTimeBeforeBreak} - ${endTimeBeforeBreak} Uhr`
            },

            openingHoursData() {
                const breakBeginTime = this.hasBreak ? this.internalEndTimeBeforeBreak?.format(TIME_FORMAT) : null;
                const breakEndTime = this.hasBreak ? this.internalBeginTimeAfterBreak?.format(TIME_FORMAT) : null;

                return {
                    'begin': this.internalBeginTimeBeforeBreak.format(TIME_FORMAT),
                    'end': this.internalEndTimeAfterBreak?.format(TIME_FORMAT) || this.internalEndTimeBeforeBreak.format(TIME_FORMAT),
                    'quantity': this.totalWorkingHours.replace(':', '.'),
                    'time': this.workStartAndEndTimes,
                    'time_details': {
                        'break_start_time': breakBeginTime,
                        'break_end_time': breakEndTime,
                        'paid_break': this.isBreakPaid,
                        'break_duration': this.breakDuration,
                    }
                }
            },
        },
        watch: {
            hasBreak(secondShift) {
                if (!secondShift) {
                    this.internalBeginTimeAfterBreak = null
                    this.internalEndTimeAfterBreak = null
                }
            },
            nightshift(hasNightShift) {
                if (hasNightShift) {
                    this.hasBreak = false;
                    this.internalBeginTimeBeforeBreak = moment(NDdefaultBeginTime);
                    this.internalEndTimeBeforeBreak = moment(NDdefaultEndTime);
                } else {
                    this.internalBeginTimeBeforeBreak = moment(defaultBeginTime);
                    this.internalEndTimeBeforeBreak = moment(defaultEndTime);
                }
            },
            value: {
                handler(newValue, oldValue) {
                    if(newValue.time_details?.break_start_time) {
                        this.hasBreak = true;
                    }
                },
                deep: true // This ensures all nested properties are watched
            }
        },
    }
</script>

<style>
.time .vc-container .vc-time-picker .vc-date {
    display: none !important;
}
</style>