export const filters = {

  servicesTypesOptions : [
    { text: 'Apotheker', value: 'substitution' },
    { text: 'PTA', value: 'pta' },
    { text: 'PKA', value: 'pka' },
  ],

  committedStatesOptions : [
    { text: 'zugesagte', value: 'true' },
    { text: 'nicht-zugesagte', value: 'false' },
  ],

  distanceOptions : [
    { text: '5 km', value: '5' },
    { text: '10 km', value: '10' },
    { text: '50 km', value: '50' },
    { text: '100 km', value: '100' },
    { text: '200 km', value: '200' },
  ],

  sortOptions : [
    {
      text: 'Datum des Termins',
      value: 'date',
    },
    {
      text: 'Erstellungsdatum',
      value: 'created',
    },
    {
      text: 'Anzahl der Termine',
      value: 'group_size',
    },
  ],
  wwsOptions : [
    {
      text: 'ADG',
      value: 'ADG',
    },
    {
      text: 'ADV Oberhausen',
      value: 'ADV Oberhausen',
    },
    {
      text: 'Aposoft',
      value: 'Aposoft',
    },
    {
      text: 'Apotechnik',
      value: 'Apotechnik',
    },
    {
      text: 'Asys',
      value: 'Asys',
    },
    {
      text: 'Cida',
      value: 'Cida',
    },
    {
      text: 'Deos',
      value: 'Deos',
    },
    {
      text: 'Gawis',
      value: 'Gawis',
    },
    {
      text: 'Hagedorn',
      value: 'Hagedorn',
    },
    {
      text: 'Infopharm',
      value: 'Infopharm',
    },
    {
      text: 'Jump',
      value: 'Jump',
    },
    {
      text: 'Lauer Fischer',
      value: 'Lauer Fischer',
    },
    {
      text: 'ONE',
      value: 'ONE',
    },
    {
      text: 'Pharmasoft',
      value: 'Pharmasoft',
    },
    {
      text: 'Pharmatechnik',
      value: 'Pharmatechnik',
    },
    {
      text: 'Prokas',
      value: 'Prokas',
    },
    {
      text: 'Promedisoft',
      value: 'Promedisoft',
    },
  ],

  isVisibleOptions : [
    {
      text: 'sichtbar',
      value: 'false',
    },
    {
      text: 'nicht-sichtbar',
      value: 'true',
    },
  ],

  isProposedOptions : [
    {
      text: 'wurde vorgeschlagen',
      value: 'true',
    },
    {
      text: 'nicht vorgeschlagen',
      value: 'false',
    },
  ],

  isDirectPharmacyOptions : [
    {
      text: 'direkt Apotheke',
      value: 'true',
    },
    {
      text: 'keine direkt Apotheke',
      value: 'false',
    },
  ],

  isNightShiftOptions : [
    {
      text: 'Nachtschicht',
      value: 'true',
    },
    {
      text: 'keine Nachtschicht',
      value: 'false',
    },
  ],

  isHBAOptions : [
    {
      text: 'HBA',
      value: 'true',
    },
    {
      text: 'kein HBA',
      value: 'false',
    },
  ],

  isRequestedOptions : [
    {
      text: 'angefragt',
      value: 'true',
    },
    {
      text: 'nicht-angefragt',
      value: 'false',
    },
  ],

  isSelfAssignableOptions : [
    {
      text: 'Selbstzuweisung an',
      value: 'true',
    },
    {
      text: 'Selbstzuweisung aus',
      value: 'false',
    },
  ],

  directProposalOptions : [
    {
      text: 'Direkter Vorschlag an',
      value: 'true',
    },
    {
      text: 'Direkter Vorschlag aus',
      value: 'false',
    },
  ],

}

export const predefinedSourceNames = ['mep24', 'noweda_member', 'noweda_customer', "firstclass_hh_rp_sh_hb"]

export const ticketFilters = {
  headers: [
    {
      text: '#',
      value: '_id'
    },
    {
      text: 'Status',
      value: 'wf_state'
    },
    {
      text: 'Referenz',
      value: 'ref_appointment_id'
    },
    {
      text: 'Titel',
      value: 'title'
    },
    {
      text: '',
      value: 'description'
    },
    {
      text: 'Typ',
      value: 'category'
    },
    {
      text: 'Datum',
      value: 'created'
    },
    {
      text: 'Ersteller',
      value: 'creator'
    },
    {
      text: 'Wiedervorlage',
      value: 'resubmit'
    },
    {
      text: 'Aktion',
      value: 'edit'
    }
  ],
  statusOptions: [{
    text: 'Alle',
    value: 'all'
  }, {
      text: 'Offen',
      value: 'open'
  }, {
      text: 'Geschlossen',
      value: 'closed'
  }]
}

export const AppointmentManagerDefaults = {
  serviceOptions: [
    { text: "Apotheker/in", value: "substitution" },
    { text: "PTA", value: "pta" },
    { text: "PKA", value: "pka" },
    { text: "QMS", value: "qms" },
    { text: "Storno", value: "storno" },
  ],
  billingOptions: [
    { text: "Stunden", value: "hours" },
    { text: "Pauschal", value: "flatrate" },
  ],
  appointmentApprovalOptions: [
    { text: "keine Angabe", value: "" },
    { text: "Termin freigegeben", value: "approved" },
    { text: "Es gibt Änderungen", value: "awaiting" },
  ],
  defaultForm: {
    begin: "08:00",
    end: "18:00",
    time: "08.00 - 18.00 Uhr",
    available_services: ["substitution"],
    billing: "hours",
    quantity: 10,
    mediation_charge: 0,
    other_costs: 0,
    other_costs_explanation: "",
    comment: "",
    internal_comment: "",
    comment_to_fb: "",
    nightshift: false,
    commited: false,
    hba: false,
    hidden: false,
    time_details: {},
    user_state: "",
  }
}
