var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { class: `mx-0 px-0` },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "d-flex", attrs: { cols: "4" } },
            [
              _c("span", [_vm._v("Mittagspause")]),
              _vm._v(" "),
              _c("v-switch", {
                staticClass: "toggle-secondshift mt-0 pt-0 ml-2",
                model: {
                  value: _vm.hasBreak,
                  callback: function ($$v) {
                    _vm.hasBreak = $$v
                  },
                  expression: "hasBreak",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-row",
                { staticClass: "time" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6", lg: "3" } },
                    [
                      _c("span", { staticClass: "text--secondary caption" }, [
                        _vm._v("Schichtbeginn vor der Pause*"),
                      ]),
                      _vm._v(" "),
                      _c("vc-date-picker", {
                        staticClass: "firstshift-begin",
                        attrs: { mode: "time", "is-required": "", is24hr: "" },
                        model: {
                          value: _vm.beginTimeBeforeBreak,
                          callback: function ($$v) {
                            _vm.beginTimeBeforeBreak = $$v
                          },
                          expression: "beginTimeBeforeBreak",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6", lg: "3" } },
                    [
                      _c("span", { staticClass: "text--secondary caption" }, [
                        _vm._v("Schichtende vor der Pause*"),
                      ]),
                      _vm._v(" "),
                      _c("vc-date-picker", {
                        staticClass: "firstshift-end",
                        attrs: {
                          mode: "time",
                          validHours: { min: _vm.endTimeBeforeBreakMinHour },
                          "is-required": "",
                          is24hr: "",
                        },
                        model: {
                          value: _vm.endTimeBeforeBreak,
                          callback: function ($$v) {
                            _vm.endTimeBeforeBreak = $$v
                          },
                          expression: "endTimeBeforeBreak",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.hasBreak
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6", lg: "3" } },
                        [
                          _c(
                            "span",
                            { staticClass: "text--secondary caption" },
                            [_vm._v("Schichtbeginn nach der Pause")]
                          ),
                          _vm._v(" "),
                          _c("vc-date-picker", {
                            staticClass: "secondshift-begin",
                            attrs: {
                              mode: "time",
                              validHours: {
                                min: _vm.beginTimeAfterBreakMinHour,
                              },
                              "is-required": "",
                              is24hr: "",
                            },
                            model: {
                              value: _vm.beginTimeAfterBreak,
                              callback: function ($$v) {
                                _vm.beginTimeAfterBreak = $$v
                              },
                              expression: "beginTimeAfterBreak",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasBreak
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6", lg: "3" } },
                        [
                          _c(
                            "span",
                            { staticClass: "text--secondary caption" },
                            [_vm._v("Schichtende nach der Pause")]
                          ),
                          _vm._v(" "),
                          _c("vc-date-picker", {
                            staticClass: "secondshift-end",
                            attrs: {
                              mode: "time",
                              validHours: { min: _vm.endTimeAfterBreakMinHour },
                              "is-required": "",
                              is24hr: "",
                            },
                            model: {
                              value: _vm.endTimeAfterBreak,
                              callback: function ($$v) {
                                _vm.endTimeAfterBreak = $$v
                              },
                              expression: "endTimeAfterBreak",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-row",
                [
                  _vm.hasBreak
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", md: "3", role: "paid-break" } },
                        [
                          _c("v-checkbox", {
                            staticClass: "mt-0",
                            attrs: {
                              label: "bezahlte Pause",
                              color: "green",
                              dense: "",
                            },
                            model: {
                              value: _vm.isBreakPaid,
                              callback: function ($$v) {
                                _vm.isBreakPaid = $$v
                              },
                              expression: "isBreakPaid",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "3" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Berechnete Arbeitszeit",
                          value: _vm.totalWorkingHours,
                          disabled: "",
                          dense: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "AUSGABE",
                          value: _vm.workStartAndEndTimes,
                          dense: "",
                          disabled: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }