<template>
  <appointment-manager
    :appointment="appointment"
    :pharmacy="pharmacy"
  ></appointment-manager>
</template>
<script>
import AppointmentManager from "./AppointmentManager.vue";

export default {
  props: {
    appointment_json: String,
    pharmacy_json: String,
  },
  components: [AppointmentManager],
  computed: {
    pharmacy() {
      return JSON.parse(this.pharmacy_json);
    },
    appointment() {
      return this.appointment_json
        ? JSON.parse(this.appointment_json)
        : undefined;
    },
  },
};
</script>
