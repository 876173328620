<template>
  <v-dialog
    v-model="isDialogOpen"
    content-class="v-application v-application--is-ltr"
  >
    <template v-slot:activator="{ on, attrs }">
      <a
        v-on="on"
        v-bind="attrs"
        style="color: inherit"
        class="edit-hours"
      >
        <v-text-field
          label="Stunden"
          v-model="value.quantity"
          required
          :rules="[beginEndTimeRule, decimalRule]"
          :hint="
            value.time ||
            defaultTimeSnippet ||
            beginEndTimeRule() ||
            decimalRule(value.quantity)
          "
          @click="reset"
          readonly
          append-icon="mdi-pencil"
          persistent-hint
          :class="{
            'hour-input': true,
            'error--text':
              decimalRule(value.quantity) !== true ||
              beginEndTimeRule() !== true,
            'stick-left': true,
          }"
        ></v-text-field>
      </a>
    </template>
    <v-card class="appointment-time-dialogue">
      <v-card-title
        >{{ nightshift ? "ND-" : "" }}Arbeitszeiten anpassen</v-card-title
      >
      <v-card-text>
        <ShiftHours
          v-model="shiftHours"
          :nightshift="value.nightshift"
          :requiredRules="requiredRules"
        />
      </v-card-text>
      <v-card-actions>
        <v-row justify="end">
          <v-col sm="4" md="2" cols="12">
            <v-btn class="back" color="orange darken-1" text @click="abort"
              >Abbrechen</v-btn
            >
          </v-col>
          <v-col sm="4" md="3" cols="12">
            <v-btn color="orange darken-1" text @click="reset">
              <v-icon aria-hidden="true">mdi-replay</v-icon>
              Zurücksetzen
            </v-btn>
          </v-col>
          <v-col sm="4" md="2" cols="12">
            <v-btn color="green darken-1" text @click="save" class="save"
              >Speichern</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validators } from "../../validators.js";
import moment from "moment";
import ShiftHours from "../ShiftHours.vue";

const TIME_FORMAT = 'HH:mm';
function timeStringToMoment(timeString) {
        return moment(`1900-01-01T${timeString}:00`);
}
function isInTimeFormat(time) {
  return moment(time, "HH:mm", true).isValid();
}

export default {
  props: {
    /** Appointment data */
    value: {
      type: Object,
      required: true,
    },
    /** Default time snippet of the pharmacy for the corresponding day (`snippet<day>`) */
    defaultTimeSnippet: {
      type: String,
      required: false,
    },
  },
  components: { ShiftHours },
  data() {
    let defaultValues = {
          begin: this.value.begin,
          end: this.value.end,
          quantity: this.value.quantity,
          time: this.value.time,
          time_details: {
            break_start_time: this.value.time_details.break_start_time ? this.value.time_details.break_start_time : null,
            break_end_time: this.value.time_details.break_end_time ? this.value.time_details.break_end_time : null,
            paid_break: !!this.value.time_details.paid_break,
            break_duration: this.value?.time_details?.break_duration
          }
        }
        // Corner cases: When default begin and end time-format is used.
        const beginIsInTimeFormat = isInTimeFormat(this.value.begin);
        if (beginIsInTimeFormat) {
          defaultValues.begin = timeStringToMoment(this.value.begin);
        }
        const endIsInTimeFormat = isInTimeFormat(this.value.end);
        if (endIsInTimeFormat) {
          defaultValues.end = timeStringToMoment(this.value.end);
        }
    return {
      shiftHours: defaultValues,
      oldValue: this.oldValue ?? this.value,
      isDialogOpen: false,
      decimalRule: validators.decimal,
      beginEndTimeRule: (_) =>
        !!this.value.begin ||
        !!this.value.end ||
        "Bitte Start- und Endzeit angeben",
      requiredRules: [validators.required],
    };
  },
  methods: {
    save() {
      const newValue = {
        ...this.value,
        begin: this.shiftHours.begin,
        end: this.shiftHours.end,
        quantity: this.shiftHours.quantity,
        time: this.shiftHours.time,
        time_details: this.shiftHours.time_details
      };
      this.$emit("input", newValue);
      this.isDialogOpen = false;
    },
    reset(isDialogOpen = true, other = {}) {
      Object.assign(this.$data, {
        ...this.$options.data.apply(this),
        isDialogOpen,
        ...other,
      });
    },
    abort() {
      this.reset(false);
    },
  },
  computed: {
    nightshift() {
      return this.value.nightshift;
    },
    isFlatRate() {
      return this.value.billing === "flatrate";
    },
  },
  watch: {
    isDialogOpen: function (newValue, oldValue) {
      if (oldValue && !newValue) {
        this.abort(); // Reset data when dialog is closed
      }
    },
    nightshift: function () {
      this.reset(false);
      if (this.oldValue == this.value) {
        this.save(); // update appointment.time
      }
    },
    /** Recalculate hours but not the appointment.time */
    value: function (newValue) {
      // Recalculate hours and keep oldValue in sync
      this.reset(false, { oldValue: newValue });
    }
  },
};
</script>

<style scope>
.hour-input .v-input__slot input {
  color: initial;
  cursor: pointer;
}

.hour-input .v-messages__message {
  color: rgba(0, 0, 0, 0.6);
}

.error--text .v-messages__message {
  color: inherit;
}

.hour-input div.v-input__slot .v-input__icon {
  cursor: pointer;
}

.hour-input .v-icon:before {
  color: initial;
}

</style>
