var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "content-class": "v-application v-application--is-ltr" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "a",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "edit-hours",
                      staticStyle: { color: "inherit" },
                    },
                    "a",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-text-field", {
                    class: {
                      "hour-input": true,
                      "error--text":
                        _vm.decimalRule(_vm.value.quantity) !== true ||
                        _vm.beginEndTimeRule() !== true,
                      "stick-left": true,
                    },
                    attrs: {
                      label: "Stunden",
                      required: "",
                      rules: [_vm.beginEndTimeRule, _vm.decimalRule],
                      hint:
                        _vm.value.time ||
                        _vm.defaultTimeSnippet ||
                        _vm.beginEndTimeRule() ||
                        _vm.decimalRule(_vm.value.quantity),
                      readonly: "",
                      "append-icon": "mdi-pencil",
                      "persistent-hint": "",
                    },
                    on: { click: _vm.reset },
                    model: {
                      value: _vm.value.quantity,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "quantity", $$v)
                      },
                      expression: "value.quantity",
                    },
                  }),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isDialogOpen,
        callback: function ($$v) {
          _vm.isDialogOpen = $$v
        },
        expression: "isDialogOpen",
      },
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "appointment-time-dialogue" },
        [
          _c("v-card-title", [
            _vm._v(
              _vm._s(_vm.nightshift ? "ND-" : "") + "Arbeitszeiten anpassen"
            ),
          ]),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c("ShiftHours", {
                attrs: {
                  nightshift: _vm.value.nightshift,
                  requiredRules: _vm.requiredRules,
                },
                model: {
                  value: _vm.shiftHours,
                  callback: function ($$v) {
                    _vm.shiftHours = $$v
                  },
                  expression: "shiftHours",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c(
                "v-row",
                { attrs: { justify: "end" } },
                [
                  _c(
                    "v-col",
                    { attrs: { sm: "4", md: "2", cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "back",
                          attrs: { color: "orange darken-1", text: "" },
                          on: { click: _vm.abort },
                        },
                        [_vm._v("Abbrechen")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { sm: "4", md: "3", cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "orange darken-1", text: "" },
                          on: { click: _vm.reset },
                        },
                        [
                          _c("v-icon", { attrs: { "aria-hidden": "true" } }, [
                            _vm._v("mdi-replay"),
                          ]),
                          _vm._v("\n            Zurücksetzen\n          "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { sm: "4", md: "2", cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "save",
                          attrs: { color: "green darken-1", text: "" },
                          on: { click: _vm.save },
                        },
                        [_vm._v("Speichern")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }