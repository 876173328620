var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-sheet",
    {
      staticClass: "v-application v-application--is-ltr vuetify-wrapper",
      attrs: {
        "content-class": "v-application v-application--is-ltr vuetify-wrapper",
      },
    },
    [
      !_vm.inEditMode
        ? _c(
            "v-btn",
            {
              staticClass: "add-appointment",
              attrs: { color: "primary", "x-small": "" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  _vm.isDrawerOpen = !_vm.isDrawerOpen
                },
              },
            },
            [
              _c("v-icon", [_vm._v("mdi-plus")]),
              _vm._v("\n    Termin Anlegen\n  "),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.inEditMode
        ? _c(
            "v-btn",
            {
              staticClass: "edit-appointment",
              attrs: { dark: "" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  _vm.isDrawerOpen = !_vm.isDrawerOpen
                },
              },
            },
            [_c("v-icon", { attrs: { small: "" } }, [_vm._v("mdi-pencil")])],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-navigation-drawer",
        {
          attrs: {
            width: "680px",
            "overlay-opacity": "0.8",
            fixed: "",
            temporary: "",
            right: "",
          },
          model: {
            value: _vm.isDrawerOpen,
            callback: function ($$v) {
              _vm.isDrawerOpen = $$v
            },
            expression: "isDrawerOpen",
          },
        },
        [
          _c(
            "v-form",
            {
              ref: "form",
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c(
                "v-container",
                { staticClass: "apt-manager", attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-6", attrs: { cols: "12" } },
                        [
                          !_vm.inEditMode
                            ? _c("h3", [_vm._v("Neuen Termin anlegen")])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.inEditMode
                            ? _c("h3", [_vm._v("Termin bearbeiten")])
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-select", {
                            staticClass: "service-dropdown mt-4",
                            attrs: {
                              "menu-props": { bottom: true, offsetY: true },
                              label: "mögliche Dienstleistungen*",
                              items: _vm.serviceOptions,
                              multiple: "",
                              rules: [_vm.validateServiceSelect],
                            },
                            model: {
                              value: _vm.formData.available_services,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "available_services",
                                  $$v
                                )
                              },
                              expression: "formData.available_services",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "nudge-right": 40,
                                transition: "scale-transition",
                                "offset-y": "",
                                disabled: _vm.inEditMode,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          {
                                            staticClass: "stick-left",
                                            attrs: {
                                              label: "Datum*",
                                              id: "date",
                                              required: "",
                                              rules: _vm.requiredRules,
                                              hint: _vm.holidays,
                                              "persistent-hint": "",
                                              autocomplete: "off",
                                            },
                                            model: {
                                              value: _vm.dateFormatted,
                                              callback: function ($$v) {
                                                _vm.dateFormatted = $$v
                                              },
                                              expression: "dateFormatted",
                                            },
                                          },
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.isDateMenuOpen,
                                callback: function ($$v) {
                                  _vm.isDateMenuOpen = $$v
                                },
                                expression: "isDateMenuOpen",
                              },
                            },
                            [
                              _vm._v(" "),
                              _c("v-date-picker", {
                                attrs: {
                                  "no-title": "",
                                  locale: "de-de",
                                  "first-day-of-week": 1,
                                },
                                model: {
                                  value: _vm.formData.date,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "date", $$v)
                                  },
                                  expression: "formData.date",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-select", {
                            staticClass: "billing",
                            attrs: {
                              label: "Art der Abrechnung",
                              items: _vm.billingOptions,
                            },
                            model: {
                              value: _vm.formData.billing,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "billing", $$v)
                              },
                              expression: "formData.billing",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.formData.billing === "hours"
                    ? _c(
                        "v-row",
                        { staticClass: "align-center" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("appointment-time-dialog", {
                                attrs: {
                                  defaultTimeSnippet: _vm.defaultTimeSnippet,
                                },
                                model: {
                                  value: _vm.formData,
                                  callback: function ($$v) {
                                    _vm.formData = $$v
                                  },
                                  expression: "formData",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12", sm: "12" } },
                        [
                          _c("v-text-field", {
                            staticClass: "stick-left",
                            attrs: {
                              type: "number",
                              min: "0",
                              label: "Vermittlungsaufschlag / Rabatt",
                              hint: "für Flying Pharmacist",
                              "persistent-hint": "",
                              prefix: "$",
                            },
                            model: {
                              value: _vm.formData.mediation_charge,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "mediation_charge",
                                  _vm._n($$v)
                                )
                              },
                              expression: "formData.mediation_charge",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12", sm: "12" } },
                        [
                          _c("v-text-field", {
                            staticClass: "stick-left",
                            attrs: {
                              type: "number",
                              min: "0",
                              label: "sonstige Kosten Honorarbestandteil",
                              hint: "für Freiberufler",
                              prefix: "$",
                              "persistent-hint": "",
                            },
                            model: {
                              value: _vm.formData.other_costs,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "other_costs",
                                  _vm._n($$v)
                                )
                              },
                              expression: "formData.other_costs",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12", sm: "12" } },
                        [
                          _c("v-text-field", {
                            staticClass: "stick-left",
                            attrs: {
                              label: "Erläuterung",
                              hint: "zu 'sonstige Kosten Honorarbestandteil' für Freiberufler",
                              "persistent-hint": "",
                            },
                            model: {
                              value: _vm.formData.other_costs_explanation,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "other_costs_explanation",
                                  $$v
                                )
                              },
                              expression: "formData.other_costs_explanation",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12", sm: "12" } },
                        [
                          _c("v-textarea", {
                            attrs: {
                              hint: "(bitte keine Einträge vornehmen)",
                              label: "Kommentar von der Apotheke",
                              "persistent-hint": "",
                              rows: "2",
                            },
                            model: {
                              value: _vm.formData.comment,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "comment", $$v)
                              },
                              expression: "formData.comment",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12", sm: "12" } },
                        [
                          _c("v-textarea", {
                            attrs: {
                              hint: "nur für interne Kommunikation",
                              label: "Interner Kommentar",
                              "persistent-hint": "",
                              rows: "2",
                            },
                            model: {
                              value: _vm.formData.internal_comment,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "internal_comment", $$v)
                              },
                              expression: "formData.internal_comment",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12", sm: "12" } },
                        [
                          _c("v-textarea", {
                            attrs: {
                              rows: "2",
                              label: "Kommentar für Freiberufler",
                              "persistent-hint": "",
                            },
                            model: {
                              value: _vm.formData.comment_to_fb,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "comment_to_fb", $$v)
                              },
                              expression: "formData.comment_to_fb",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              id: "nightshift",
                              label: "Nachtschicht?",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.formData.nightshift,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "nightshift", $$v)
                              },
                              expression: "formData.nightshift",
                            },
                          }),
                          _vm._v(" "),
                          !!_vm.pharmacy.is_angel
                            ? _c("v-checkbox", {
                                attrs: {
                                  label: "feste Zusage?",
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.formData.commited,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "commited", $$v)
                                  },
                                  expression: "formData.commited",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("v-checkbox", {
                            attrs: {
                              disabled: _vm.pharmacy.is_angel,
                              label: "Engel?",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.formData.is_angel,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "is_angel", $$v)
                              },
                              expression: "formData.is_angel",
                            },
                          }),
                          _vm._v(" "),
                          _c("v-checkbox", {
                            attrs: {
                              label: "HBA zwingend erforderlich?",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.formData.hba,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "hba", $$v)
                              },
                              expression: "formData.hba",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.inEditMode
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  label: "Rechnungsfreigabe",
                                  items: _vm.appointmentApprovalOptions,
                                },
                                model: {
                                  value: _vm.formData.user_state,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "user_state", $$v)
                                  },
                                  expression: "formData.user_state",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "save-appointment",
                              attrs: {
                                disabled: !_vm.valid,
                                id: "submit-apt",
                                color: "primary",
                              },
                              on: { click: _vm.onSaveButtonClicked },
                            },
                            [_vm._v("\n              Speichern\n            ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "close-drawer",
                              on: {
                                click: function ($event) {
                                  _vm.isDrawerOpen = false
                                },
                              },
                            },
                            [_vm._v("Abbrechen")]
                          ),
                          _vm._v(" "),
                          _c("AppointmentsClashesDialog", {
                            attrs: {
                              clashes: _vm.clashes,
                              appointments: [_vm.formData],
                            },
                            on: {
                              confirm: _vm.submitData,
                              abort: function ($event) {
                                _vm.clashes = []
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }